// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-default-js": () => import("./../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-books-js": () => import("./../src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-research-js": () => import("./../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-book-js": () => import("./../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-news-article-js": () => import("./../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

